<template>
  <div v-if="authUser" class="container-xxl">
    <div class="row">
      <div class="col">
        <UserPhotoForm class="my-3"></UserPhotoForm>

        <UserProfileForm class="my-3"></UserProfileForm>

        <UserPasswordForm class="my-3"></UserPasswordForm>

        <!-- TODO: DEPRECATED -->
<!--          <UserSubscriptionForm
          v-if="authUserIsCoach"
          class="my-3"
        ></UserSubscriptionForm>

        <UserUpgradeForm
          v-if="!authUserIsCoach && !authUserIsAdmin"
          class="my-3"
        ></UserUpgradeForm> -->

        <div v-if="authUserIsCoach && configUIMode" class="card my-3">
          <div class="card-body">
            <h5 class="card-title">
              Subscription
            </h5>
            <p>
              <span v-if="subscriptionPlan.stripeSubscriptionId != null"
                class="border-bottom">
                You are subscribed to the {{ subscriptionPlan.planName }}
              </span>
              <span v-if="subscriptionPlan.stripeSubscriptionId == null">
                You are not subscribed to a plan.
                Please subscribe to a plan.
              </span>
            </p>
            <IconButton
            v-if="subscriptionPlan.stripeSubscriptionId != null"
            @click="fetchBillingPortal"
            :icon="'credit-card'"
            :text="'Manage billing'"
            :isLoading="isFetchingBillingUrl"
            class="btn btn-sm btn-outline-dark mt-2"
          ></IconButton>
          <IconButton
            v-if="subscriptionPlan.stripeSubscriptionId == null"
            @click="createNewSubscriptionPlan"
            :icon="'credit-card'"
            :text="'Subscribe Now'"
            :isLoading="isFetchNewPlanUrl"
            class="btn btn-sm btn-outline-dark mt-2"
          ></IconButton>
          </div>
        </div>

        <div v-if="authUserIsCoach && configUIMode" class="card my-3">
          <div class="card-body">
            <h5 class="card-title">
              You are currently in <span class="border-bottom">
                {{ configUIMode }}</span> mode.
            </h5>
            <button
              @click="changeUI('coach')"
              v-if="configUIMode === 'athlete'"
              class="btn btn-primary"
            >Switch to <strong>Coach</strong> mode</button>
            <button
              @click="changeUI('athlete')"
              v-if="configUIMode === 'coach'"
              class="btn btn-primary"
            >Switch to <strong>Athlete</strong> mode</button>
          </div>
        </div>

        <div class="my-3 pt-3 d-flex align-items-center border-top">
          <div class="flex-grow-1 pe-3">
            <button @click="showConfirmModal = true" class="btn btn-danger">Logout</button>
          </div>
          <!-- TODO: DEPRECATED -->
          <!-- <button
            @click="showConfirmDeleteModal = true"
            v-if="authUserIsAthlete || authUserIsCoach"
            class="btn btn-link text-danger"
          >Delete my account</button> -->
        </div>

        <portal to="modal">
          <transition name="modal">
            <ConfirmModal
              @confirm="logout"
              @close="showConfirmModal = false"
              v-model="showConfirmModal"
              v-if="showConfirmModal"
            ></ConfirmModal>
          </transition>
        </portal>

        <portal to="modal">
          <transition name="modal">
            <ConfirmModal
              @confirm="deleteAccount"
              @close="showConfirmDeleteModal = false"
              v-model="showConfirmDeleteModal"
              v-if="showConfirmDeleteModal"
              :forceConfirmation="true"
              :yesLabel="'Delete my account'"
              :message="`
                You are about to delete your Trainifier account.
                This action cannot be undone.
                Proceed with caution.
              `"
            ></ConfirmModal>
          </transition>
        </portal>

        <portal to="modal">
          <transition name="modal">
            <LoadingModal
              v-model="isDeleting"
              v-if="isDeleting"
              :message="'Deleting your account...'"
            ></LoadingModal>
          </transition>
        </portal>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { httpDelete, httpGet } from '@/core/http';

export default {
  name: 'Account',
  components: {
    UserPhotoForm: () => import('@/components/form/UserPhotoForm'),
    UserProfileForm: () => import('@/components/form/UserProfileForm'),
    UserPasswordForm: () => import('@/components/form/UserPasswordForm'),
    // UserSubscriptionForm: () => import('@/components/form/UserSubscriptionForm'),
    // UserUpgradeForm: () => import('@/components/form/UserUpgradeForm'),
    ConfirmModal: () => import('@/components/modal/ConfirmModal'),
    LoadingModal: () => import('@/components/modal/LoadingModal'),
    IconButton: () => import('@/components/button/IconButton'),
  },
  computed: {
    ...mapState('auth', ['authUser']),
    ...mapGetters('auth', ['authUserIsAthlete', 'authUserIsCoach', 'authUserIsAdmin']),
    ...mapState('config', ['configUIMode']),
  },
  methods: {
    changeUI(value) {
      this.$store.dispatch('config/setUIMode', {
        vm: this,
        value,
      });
    },
    logout() {
      this.showConfirmModal = false;
      this.$store.dispatch('calendar/resetCalendar');
      this.$store.dispatch('client/resetRequest');
      this.$store.dispatch('comment/resetComment');
      this.$store.dispatch('feed/resetFeed');
      this.$store.dispatch('library/reset');
      this.$store.dispatch('metric/reset');
      this.$store.dispatch('notification/resetNotification');
      this.$store.dispatch('program/reset');
      this.$store.dispatch('session/reset');
      this.$store.dispatch('workout/resetWorkout');
      this.$store.dispatch('auth/logout');
    },
    async fetchBillingPortal() {
      if (!this.authUserIsCoach) return;
      this.isFetchingBillingUrl = true;
      try {
        const { data: { url } } = await httpGet('/subscription/billingurl');
        window.location.href = url;
        // this.billingUrl = url;
        console.log(url);
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isFetchingBillingUrl = false;
      }
    },
    async deleteAccount() {
      try {
        this.showConfirmDeleteModal = false;
        this.isDeleting = true;
        await httpDelete('/user');
        this.logout();
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isDeleting = false;
      }
    },
    // TODO: DEPRECATED
    async fetchSubscriptionPlan() {
      if (!this.authUserIsCoach) return;
      try {
        const { data } = await httpGet('/subscription');
        this.subscriptionPlan = data;
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      }
    },
    async createNewSubscriptionPlan() {
      if (!this.authUserIsCoach) return;
      this.isFetchNewPlanUrl = true;
      try {
        const { data: { url } } = await httpGet('/subscription/existingUserNewPlan');
        window.location.href = url;
        // this.billingUrl = url;
        console.log(url);
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isFetchNewPlanUrl = false;
      }
    },
  },
  data() {
    return {
      showConfirmModal: false,
      showConfirmDeleteModal: false,
      isFetchingBillingUrl: false,
      isFetchNewPlanUrl: false,
      plan: null,
      isDeleting: false,
      subscriptionPlan: null,
    };
  },
  mounted() {
    // TODO: DEPRECATED
    this.fetchSubscriptionPlan();
  },
};
</script>
